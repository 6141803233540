import React from 'react';
import { Box, Flex, Heading, VStack } from '@chakra-ui/react';
import HomeBanner from '@/components/Layouts/Banner/HomeBanner';
import PageFooter from '@/components/Layouts/Footer/PageFooter';
import NavbarMain from '@/components/Layouts/Navbar/NavbarMain';
import StudioList from '@/components/StudioList';

const LandingPage = () => (
  <VStack spacing={0} minHeight="100vh" overflow="auto">
    <NavbarMain />
    <HomeBanner />
    <Flex
      direction="column"
      width="full"
      justifyContent="center"
      maxW="1300px"
      mx="auto"
      py={10}
    >
      <Box mt={10}>
        <Heading size="lg" p={4} mx={2}>
          Discover Studios
        </Heading>
      </Box>
      <Box>
        <StudioList />
      </Box>
      <Box>
        <PageFooter />
      </Box>
    </Flex>
  </VStack>
);

export default LandingPage;
