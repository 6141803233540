import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import JoinNowCTA from '@/components/Layouts/Discover/JoinNowCTA';
import StudioOwnersJoinCTA from '@/components/Layouts/Discover/StudioOwnersJoinCTA';
import FAQ from '@/components/Layouts/FAQ/FAQ';
import Hero from '@/components/Layouts/ListYourStudio/Hero';
import { CTACard } from '@/components/Layouts/Discover/CTACard';
import { useAuthStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import { handleStudioNavigation } from '@/components/Utilities/helper';

export interface CTAButtonProps {
  handleClick: () => void;
  isLoading: boolean;
}

const ListYourStudioCTAPage: React.FC = () => {
  const { user, isLoading, isAuthenticated } = useAuthStore();
  const navigate = useNavigate();
  const showToast = useShowToastTop();

  const handleClick = () => {
    if (!isAuthenticated) {
      navigate('/auth/sign_in');
      return;
    }
    handleStudioNavigation(navigate, showToast, user);
  };

  return (
    <Flex
      direction="column"
      width="full"
      justifyContent="center"
      maxW="1300px"
      mx="auto"
      py={5}
    >
      <Box mb={10}>
        <Hero handleClick={handleClick} isLoading={isLoading} />
      </Box>
      <Box mb={10}>
        <CTACard />
      </Box>
      <Box mb={10}>
        <StudioOwnersJoinCTA />
      </Box>
      <Box mb={10}>
        <FAQ />
      </Box>
      <Box>
        <JoinNowCTA handleClick={handleClick} isLoading={isLoading} />
      </Box>
    </Flex>
  );
};

export default ListYourStudioCTAPage;
