import { Box, Button, Center } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateStudioCard = () => {
  const navigate = useNavigate();

  return (
    <Box
      as={Button}
      w="full"
      h="465px"
      mx="auto"
      bg="powder.500"
      shadow="lg"
      rounded="md"
      border="2px"
      borderStyle="dashed"
      borderColor="garageGrey.300"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _hover={{
        bg: 'powder.600', // Optional: Change bg color on hover
      }}
      onClick={() => navigate('/studios/new')}
    >
      <Center
        fontSize="lg"
        height={20}
        width={40}
        borderRadius="full"
        border="2px"
        borderStyle="dashed"
        fontWeight="bold"
        color="garageGrey.400"
      >
        + Add Studio
      </Center>
    </Box>
  );
};

export default CreateStudioCard;
