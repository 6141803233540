import React, { useState, useEffect, memo } from 'react';
import { StripeElementsOptions, loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import '@/components/BookingConfirmation/strip-ui.scss';
import { useReservationStore } from '@/services/store';
import { Studio } from '@/hooks/useStudios';
import CheckoutForm from '@/components/BookingConfirmation/CheckoutForm';

interface StripeFormProps {
  studio?: Studio;
}

const StripeForm: React.FC<StripeFormProps> = ({ studio }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const reservationId = useReservationStore((state) => state.reservation)?.id;
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (studio?.stripeAccountId) {
      const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHER_KEY, {
        stripeAccount: studio?.stripeAccountId,
      });
      setStripePromise(stripe);
    } else {
      setStripePromise(null);
    }
  }, [studio]);

  useEffect(() => {
    if (!reservationId) return;

    fetch('/api/v1/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ reservationId }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data?.data?.clientSecret));
  }, [reservationId]);

  const appearance: StripeElementsOptions['appearance'] = {
    theme: 'flat',
    variables: {
      colorPrimary: '#333232',
      colorBackground: '#ffffff',
    },
    rules: {
      '.Input': {
        border: '1px solid #ccc',
      },
      '.Tab': {
        border: '1px solid #ccc',
      },
    },
  };

  const options: StripeElementsOptions = {
    clientSecret: clientSecret || '',
    appearance,
  };

  return (
    <div className="stripe-checkout-form">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default memo(StripeForm);
