import {
  IconButton,
  HStack,
  useColorMode,
  chakra,
  ListItem,
  OrderedList,
  UnorderedList,
  Heading,
  Tooltip,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatUnderlined,
  MdLooksOne,
  MdLooksTwo,
  MdLooks3,
  MdLooks4,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
} from 'react-icons/md';
import {
  useSlate,
  ReactEditor,
  RenderLeafProps,
  RenderElementProps,
} from 'slate-react';
import { Editor, Transforms, Element as SlateElement } from 'slate';
import { HistoryEditor } from 'slate-history';

type EditorProps = Editor | ReactEditor | HistoryEditor;
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];

export interface MyElement extends SlateElement {
  align?: any;
  type: string;
}

const isBlockActive = (
  editor: EditorProps,
  format: string,
  blockType = 'type',
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as any)[blockType] === format,
    }),
  );

  return !!match;
};

const isMarkActive = (editor: EditorProps, format: string) => {
  const marks: any = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor: EditorProps, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes((n as MyElement).type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<MyElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      // eslint-disable-next-line no-nested-ternary
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: EditorProps, format: string) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const MarkButton = ({
  format,
  icon,
  tooltipLabel,
}: {
  format: string;
  icon: ReactElement;
  tooltipLabel: string;
}) => {
  const editor = useSlate();
  return (
    <Tooltip label={tooltipLabel} aria-label={tooltipLabel}>
      <IconButton
        variant="outline"
        colorScheme="garageGrey"
        isActive={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        aria-label={format}
        icon={icon}
        borderWidth={0}
        fontSize="20px"
      />
    </Tooltip>
  );
};

export const BlockButton = ({
  format,
  icon,
  tooltipLabel,
}: {
  format: string;
  icon: ReactElement;
  tooltipLabel: string;
}) => {
  const editor = useSlate();
  return (
    <Tooltip label={tooltipLabel} aria-label={tooltipLabel}>
      <IconButton
        variant="outline"
        colorScheme="garageGrey"
        isActive={isBlockActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        aria-label={format}
        icon={icon}
        borderWidth={0}
        fontSize="20px"
      />
    </Tooltip>
  );
};

export const Toolbar = () => (
  <HStack borderWidth="0 0 1px 0" padding="10px 5px" spacing="5px" wrap="wrap">
    <MarkButton format="bold" icon={<MdFormatBold />} tooltipLabel="Bold" />
    <MarkButton
      format="italic"
      icon={<MdFormatItalic />}
      tooltipLabel="Italic"
    />
    <MarkButton
      format="underline"
      icon={<MdFormatUnderlined />}
      tooltipLabel="Underline"
    />
    <BlockButton
      format="heading-one"
      icon={<MdLooksOne />}
      tooltipLabel="Heading One"
    />
    <BlockButton
      format="heading-two"
      icon={<MdLooksTwo />}
      tooltipLabel="Heading Two"
    />
    <BlockButton
      format="heading-three"
      icon={<MdLooks3 />}
      tooltipLabel="Heading Three"
    />
    <BlockButton
      format="heading-four"
      icon={<MdLooks4 />}
      tooltipLabel="Heading Four"
    />
    <BlockButton
      format="numbered-list"
      icon={<MdFormatListNumbered />}
      tooltipLabel="Numbered List"
    />
    <BlockButton
      format="bulleted-list"
      icon={<MdFormatListBulleted />}
      tooltipLabel="Bulleted List"
    />
    <BlockButton
      format="left"
      icon={<MdFormatAlignLeft />}
      tooltipLabel="Align Left"
    />
    <BlockButton
      format="center"
      icon={<MdFormatAlignCenter />}
      tooltipLabel="Align Center"
    />
    <BlockButton
      format="right"
      icon={<MdFormatAlignRight />}
      tooltipLabel="Align Right"
    />
  </HStack>
);

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const style = { textAlign: (element as any)?.align };

  switch ((element as MyElement).type) {
    case 'list-item':
      return (
        <ListItem style={style} {...attributes}>
          {children}
        </ListItem>
      );
    case 'numbered-list':
      return (
        <OrderedList style={style} {...attributes}>
          {children}
        </OrderedList>
      );
    case 'bulleted-list':
      return (
        <UnorderedList style={style} {...attributes}>
          {children}
        </UnorderedList>
      );
    case 'heading-one':
      return (
        <Heading style={style} as="h1" size="3xl" {...attributes}>
          {children}
        </Heading>
      );
    case 'heading-two':
      return (
        <Heading style={style} as="h2" size="2xl" {...attributes}>
          {children}
        </Heading>
      );
    case 'heading-three':
      return (
        <Heading style={style} as="h3" size="xl" {...attributes}>
          {children}
        </Heading>
      );
    case 'heading-four':
      return (
        <Heading style={style} as="h4" size="lg" {...attributes}>
          {children}
        </Heading>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  const { colorMode } = useColorMode();

  if ((leaf as any)?.bold) {
    // eslint-disable-next-line no-param-reassign
    children = <strong>{children}</strong>;
  }

  if ((leaf as any)?.code) {
    // eslint-disable-next-line no-param-reassign
    children = (
      <chakra.code
        padding="3px"
        backgroundColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
        fontSize="90%"
      >
        {children}
      </chakra.code>
    );
  }

  if ((leaf as any)?.italic) {
    // eslint-disable-next-line no-param-reassign
    children = <em>{children}</em>;
  }

  if ((leaf as any)?.underline) {
    // eslint-disable-next-line no-param-reassign
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
