import { useQuery } from '@tanstack/react-query';
import ApiClient from '@/services/api-client';
import { flattenAttributes } from '@/components/Utilities/helper';
import { StudioSearchQuery } from '@/services/store';

const apiClient = new ApiClient<any>('v1/studios/top_searched');

const useTopSearchStudios = (query?: StudioSearchQuery) => {
  const { data, ...rest } = useQuery({
    queryKey: ['topSearchStudios', query],
    queryFn: () =>
      apiClient.getAll({
        params: {
          studio_name: query?.studioName || '',
          room_name: query?.roomName || '',
          address: query?.address || '',
        },
      }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  return { data: flattenAttributes(data?.data || []), ...rest };
};

export default useTopSearchStudios;
