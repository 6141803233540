import { Box, forwardRef, GridItem } from '@chakra-ui/react';

// Custom reusable component with default border styles
export const BorderedBox = forwardRef((props, ref) => (
  <Box
    ref={ref}
    border="1px"
    borderColor="powder.600"
    boxShadow="lg"
    {...props} // Allows overriding or adding other styles
  />
));

export const BorderedGridItem = forwardRef((props, ref) => (
  <GridItem
    ref={ref}
    border="1px"
    borderColor="powder.600"
    {...props} // Allows overriding or adding other styles
  />
));
