import React, { useRef } from 'react';
import { Badge, Box, Flex, Icon, Image, Stack, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { FaLocationDot } from 'react-icons/fa6';
import { Studio } from '@/hooks/useStudios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NextArrow from '@/components/StudioIndex/NextArrow';
import PreviousArrow from '@/components/StudioIndex/PreviousArrow';
import TextWithTooltip from '@/components/TextWithTooltip';

interface Props {
  studio: Studio;
}

const StudioCard = ({ studio }: Props) => {
  const location = useLocation();
  const bookingDuration = (studio.minimumBookingDuration || 60) / 60;

  const imagesArray = Array.isArray(studio.images) ? studio.images : [];
  const topImages = imagesArray.slice(0, 5); // Get top 5 images
  const studioNameRef = useRef<HTMLDivElement>(null);
  const roomNameRef = useRef<HTMLDivElement>(null);
  const addressRef = useRef<HTMLDivElement>(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
  };

  return (
    <Link
      to={`/studios/${studio?.slug ? studio.slug : studio.id}${location.search}`}
      target="_blank"
    >
      <Flex
        direction="column"
        maxW={{ base: '100%', sm: 'sm' }} // Responsive width for mobile
        w="100%"
        outline={0}
        boxShadow={0}
        h="400px"
        bg="white"
        position="relative"
      >
        <Badge
          colorScheme="orange"
          position="absolute"
          top="10px"
          right="10px"
          zIndex="1"
          borderRadius="full"
          px={3}
          py={1}
        >
          min {bookingDuration} hr
        </Badge>{' '}
        {topImages.length > 1 ? (
          <Slider {...settings}>
            {/* Badge for minimum booking duration */}
            {topImages.map((image, index) => (
              <div key={index}>
                <Image
                  src={image}
                  borderTopRadius="lg"
                  minW="300px"
                  height="200px"
                  objectFit="cover"
                  alt={`Studio ${studio.id} Image ${index + 1}`}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <Image
            src={topImages[0] || ''}
            borderTopRadius="lg"
            width="100%"
            height="200px"
            alt={`Studio ${studio.id} Image`}
          />
        )}
        <Flex
          flex="1"
          direction="column"
          p={1.5}
          justifyContent="space-between"
        >
          <Stack spacing="2">
            <TextWithTooltip
              text={studio.roomName || ''}
              fontSize="xl"
              ref={roomNameRef}
            />
            <TextWithTooltip
              text={studio.studioName}
              fontSize="md"
              ref={studioNameRef}
              fontWeight="medium"
            />
            <Flex gap={1.5} alignItems="baseline">
              <Icon
                as={FaLocationDot}
                alignSelf="baseline"
                mt={1}
                fontSize="sm"
              />
              <TextWithTooltip
                text={` ${studio.location.address}`}
                fontSize="sm"
                ref={addressRef}
                fontWeight="medium"
                color="gray.500"
              />
            </Flex>
          </Stack>
          <Box>
            <Text display="inline" fontSize="sm" fontWeight="bold">
              From{' '}
            </Text>
            <Text
              display="inline"
              fontSize="xl"
              fontWeight="bold"
              // borderBottom="1px solid"
            >
              S${studio.price}
            </Text>
            <Text display="inline" fontSize="sm" fontWeight="bold">
              {' '}
              /hour
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Link>
  );
};

export default StudioCard;
