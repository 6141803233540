import React from 'react';
import {
  ButtonGroup,
  Container,
  IconButton,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';
import { FaFacebookSquare, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Divider } from 'antd';
import logo from '@/images/MuseHive_Horizontal-logo-Main_RGB.png';

const PageFooter = () => (
  <Container as="footer" role="contentinfo" py={12} maxWidth="1300px">
    <Divider />
    <Stack spacing={{ base: '4', md: '5' }}>
      <Stack
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
        align="center"
        spacing={{ base: '4', md: '0' }}
      >
        <Image src={logo} height={14} width="auto" />
        <ButtonGroup variant="tertiary">
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/muse-hive"
            aria-label="LinkedIn"
            icon={<FaLinkedin />}
            size="md"
            target="_blank"
          />
          <IconButton
            as="a"
            href="https://www.facebook.com/profile.php?id=61565740161003"
            aria-label="GitHub"
            icon={<FaFacebookSquare />}
            size="md"
            target="_blank"
          />
          <IconButton
            as="a"
            href="https://www.instagram.com/musehivebooking/"
            aria-label="Twitter"
            icon={<FaInstagram />}
            size="md"
            target="_blank"
          />
        </ButtonGroup>
      </Stack>
      <Text
        fontSize="sm"
        color="fg.subtle"
        textAlign={{ base: 'center', md: 'left' }}
      >
        &copy; {new Date().getFullYear()} MuseHive Booking, Pte Ltd. All rights
        reserved.
      </Text>
    </Stack>
  </Container>
);

export default PageFooter;
