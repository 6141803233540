import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import theme from '@/components/ColorTheme';
import useAuthenticateOnLoad from '@/hooks/useAuthenticateOnLoad';
import router from '@/routes/router';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useAuthStore } from '@/services/store';

const queryClient = new QueryClient();

const AuthenticationChecker = () => {
  const { isPending } = useAuthenticateOnLoad();
  const { isLoading } = useAuthStore();
  if (isPending || isLoading) {
    return <LoadingSpinner height="100vh" />;
  }
};

const App = () => {
  const { isLoading } = useAuthStore();
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthenticationChecker />
        {!isLoading && <RouterProvider router={router} />}
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
