import React from 'react';
import { FormControl, Select } from '@chakra-ui/react';
import {
  selectFieldStyles,
  useBorderStyle,
} from '@/components/inputfields/inputUtilities';
import { timeOptions } from '@/components/inputfields/TimeConversion';
import { SetLocalFormData } from '@/components/searchValidation';
import CustomLabel from '@/components/Forms/CustomLabel';

interface StartTimeInputProps {
  setLocalFormData: SetLocalFormData;
  localFormData: { time: string };
  defaultValue: string | undefined | null;
  width?: string | undefined;
  height?: string | undefined;
  enableCustomStyles?: boolean;
  label?: string;
  borderColor?: string;
}

const StartTimeInput = ({
  setLocalFormData,
  localFormData,
  defaultValue,
  height,
  enableCustomStyles = true,
  label = 'Time',
  width = 'auto',
  borderColor = 'powder.600'
}: StartTimeInputProps) => {
  const styles = enableCustomStyles ? selectFieldStyles() : {};
  const borderStyles = useBorderStyle('130px', 0, 'right');
  const initialTimeValue = timeOptions.find(
    (option) => option.key === defaultValue,
  )
    ? defaultValue
    : '';
  const selectedTime = localFormData.time || initialTimeValue || '';

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTime = e.target.value;
    if (newTime) {
      setLocalFormData((prev: any) => ({
        ...prev,
        time: newTime,
      }));
    }
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const getIcon = () => (enableCustomStyles ? <></> : undefined);

  return (
    <FormControl {...(enableCustomStyles ? borderStyles : {})} w={width}>
      <CustomLabel labelText={label} mb={0} />
      <Select
        name="time"
        value={selectedTime}
        onChange={handleTimeChange}
        {...styles}
        icon={getIcon()}
        height={height}
        focusBorderColor='honey.300'
        _hover={{ borderColor: 'honey.300'}}
        borderColor={borderColor}
      >
        <option value="" disabled>
          Select Time
        </option>
        {timeOptions.map((option) => option)}
      </Select>
    </FormControl>
  );
};

export default React.memo(StartTimeInput);
