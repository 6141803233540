import {
  CalendarIcon,
  ChevronRightIcon,
  EditIcon,
  LockIcon,
} from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Link as ChakraLink,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaCalendarCheck, FaFileImport, FaHome } from 'react-icons/fa';
import { MdOutlinePayment } from 'react-icons/md';
import { Link as ReactRouterLink } from 'react-router-dom';
import { RiAdminFill } from 'react-icons/ri';
import { useAuthStore, UserDetails } from '@/services/store';
import { BorderedBox } from '../Utilities/CustomBorder';

type CustomLinkProps = {
  icon: React.ElementType;
  children: React.ReactNode;
  redirect: string;
  as?: React.ElementType;
};

interface SideBarMenuItemProps extends Omit<CustomLinkProps, 'children'> {
  label: string;
}

const getName = (user: UserDetails | null) => {
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user?.email) {
    return user.email;
  }
  return 'Loading...';
};

const SidebarProfileSection = () => {
  const { user } = useAuthStore();
  const name = getName(user);

  return (
    <Box
      p={5}
      display="flex"
      alignContent="center"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      <Avatar
        size="xl"
        bgColor="honey.500"
        name={name}
        src="https://bit.ly/tioluwani-kolawole"
      />
      <Box
        position="absolute"
        right={0}
        top={0}
        transform="translate(50%, -50%)"
      />
      <Text mt={3} fontSize="lg" fontWeight="bold" whiteSpace="nowrap">
        {name}
      </Text>
      <Button
        mt={3}
        size="xs"
        bg="white"
        color="blue.500"
        _hover={{ color: 'blue.600', bg: 'white', textDecoration: 'underline' }}
        as={ReactRouterLink}
        to="user_profile_edit"
      >
        <Icon as={EditIcon} mr={2} />
        Edit Profile
        <Icon as={ChevronRightIcon} ml={2} />
      </Button>
    </Box>
  );
};

const CustomLink: React.FC<CustomLinkProps> = ({
  icon,
  children,
  redirect,
  as,
}) => {
  const handleClick = () => {
    if (as === 'a') {
      window.location.href = redirect; // Hard reload the page
    }
  };

  return (
    <Flex
      align="center"
      width="100%" // Ensure it takes up the full width of the parent container
      py={1} // Add some padding for better touch target size
      gap={4}
      onClick={handleClick} // Handle the click event
    >
      <Icon as={icon} boxSize={4} />
      <ChakraLink
        as={as || ReactRouterLink}
        to={redirect}
        flex="1"
        textAlign="center"
      >
        {children}
      </ChakraLink>
    </Flex>
  );
};

const SidebarContent: React.FC = () => {
  const { user } = useAuthStore();
  const bookingImportMenuItem = [
    { icon: FaFileImport, label: 'Booking Import', redirect: 'booking_import' },
  ];

  const manageReservationMenuItem = [
    {
      icon: CalendarIcon,
      label: 'Manage Reservations',
      redirect: 'manage_reservations',
    },
  ];

  const sideBarMenuItems: SideBarMenuItemProps[] = [
    {
      icon: FaHome,
      label: 'My Studios',
      redirect: 'manage_studios',
    },
    ...(user?.studioOwner
      ? [...manageReservationMenuItem, ...bookingImportMenuItem]
      : []),
    {
      icon: FaCalendarCheck,
      label: 'Bookings',
      redirect: 'bookings',
    },
    {
      icon: MdOutlinePayment,
      label: 'Payment Settings',
      redirect: 'payment_settings',
    },
    {
      icon: LockIcon,
      label: 'Change Password',
      redirect: 'change_password',
    },
  ];

  if (user?.isAdmin) {
    sideBarMenuItems.push({
      icon: RiAdminFill,
      label: 'Admin Panel',
      redirect: '/admin',
      as: 'a',
    });
  }

  const paddingBottom = sideBarMenuItems.length < 9 ? 10 : 20;

  return (
    <BorderedBox borderRadius="10px">
      <VStack
        p={5}
        spacing={4}
        color="garageGrey.500"
        divider={<StackDivider borderColor="gray.200" />}
        align="stretch"
        pb={paddingBottom}
      >
        <SidebarProfileSection />
        {sideBarMenuItems.map(({ icon, label, redirect, as }) => (
          <CustomLink key={label} icon={icon} as={as} redirect={redirect}>
            {label}
          </CustomLink>
        ))}
      </VStack>
    </BorderedBox>
  );
};

const Sidebar = () => (
  <Box left={0}>
    <SidebarContent />
  </Box>
);

export default React.memo(Sidebar);
