import React, { memo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import CustomLabel from '@/components/Forms/CustomLabel';

interface CustomAccordionItem {
  title: string;
  content: React.ReactNode;
}

interface CustomAccordionProps {
  items: CustomAccordionItem[];
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ items }) => (
  <Accordion allowMultiple>
    {items.map((item, index) => (
      <AccordionItem
        key={index}
        border="1px"
        borderColor="powder.600"
        borderRadius="md"
        _hover={{ borderColor: 'honey.300'}}
      >
        <AccordionButton
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomLabel labelText={item.title} m={0} />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>{item.content}</AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
);

export default memo(CustomAccordion);
