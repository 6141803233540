import * as z from 'zod';
import { parse, isEqual, isBefore } from 'date-fns';

type TimeSlot = {
  openTime?: string;
  closeTime?: string;
};

const convertTimeToDate = (time: string): Date =>
  parse(time, 'HH:mm', new Date());

export const timeSlotsDoNotOverlap = (
  timeSlots: TimeSlot[],
): { index: number; message: string }[] | true => {
  const overlapErrors: { index: number; message: string }[] = [];

  timeSlots.forEach((currentSlot, i) => {
    const currentStart = convertTimeToDate(currentSlot.openTime || '');
    const currentEnd = convertTimeToDate(currentSlot.closeTime || '');

    timeSlots.slice(i + 1).forEach((nextSlot, j) => {
      const nextStart = convertTimeToDate(nextSlot.openTime || '');
      const nextEnd = convertTimeToDate(nextSlot.closeTime || '');

      const overlaps =
        (isBefore(nextStart, currentEnd) && isBefore(currentStart, nextEnd)) ||
        isEqual(nextStart, currentStart) ||
        isEqual(nextEnd, currentEnd);

      if (overlaps) {
        overlapErrors.push({
          index: i + j + 1,
          message: `Please update this operating hour to avoid overlapping with operating hour ${i + 1}.`,
        });
      }
    });
  });

  return overlapErrors.length > 0 ? overlapErrors : true;
};

export const operatingHourDetailSchema = z.object({
  id: z.number().nullish().optional(),
  isClosed: z.boolean(),
  timeSlots: z
    .array(
      z.object({
        openTime: z
          .string()
          .optional()
          .refine(
            (val) =>
              (val ?? '') === '' ||
              /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val ?? ''),
            {
              message: 'Invalid time format. Please use HH:MM.',
            },
          ),
        closeTime: z
          .string()
          .optional()
          .refine(
            (val) =>
              (val ?? '') === '' ||
              /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val ?? ''),
            {
              message: 'Invalid time format. Please use HH:MM.',
            },
          ),
        id: z.number().nullish().optional(),
      }),
    )
    .superRefine((timeSlots, ctx) => {
      const overlapResult = timeSlotsDoNotOverlap(timeSlots);
      if (overlapResult !== true) {
        overlapResult.forEach(({ index, message }) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index],
            message,
          });
        });
      }
    }),
});

const slateNodeSchema: any = z.object({
  type: z.string().optional(),
  children: z.array(z.lazy(() => slateNodeSchema)),
  text: z.string().optional(),
});

export const operatingHoursSchema = z.object({
  monday: operatingHourDetailSchema,
  tuesday: operatingHourDetailSchema,
  wednesday: operatingHourDetailSchema,
  thursday: operatingHourDetailSchema,
  friday: operatingHourDetailSchema,
  saturday: operatingHourDetailSchema,
  sunday: operatingHourDetailSchema,
});

const TextNodeSchema = z.object({
  text: z.string(),
});

const ElementNodeSchema: any = z.object({
  type: z.string(),
  children: z.array(z.union([TextNodeSchema, z.lazy(() => ElementNodeSchema)])),
});

const isValidSlateArray = (value: any) => {
  if (typeof value === 'string') {
    try {
      const parsedValue = JSON.parse(value);
      return Array.isArray(parsedValue);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return false;
    }
  }
  return Array.isArray(value);
};

const SlateDocumentSchema = z.array(ElementNodeSchema);

export const MAX_LENGTH = 3000;

export const studioFormSchema = z
  .object({
    studioName: z
      .string()
      .min(2, 'Studio name must be at least 2 characters long.')
      .max(50, 'Studio name cannot exceed 50 characters.'),
    roomName: z
      .string()
      .min(2, 'Room name must be at least 2 characters long ')
      .max(50, 'Room name cannot exceed 50 characters.'),
    description: z.union([
      SlateDocumentSchema,
      z.string().refine(isValidSlateArray, {
        message: 'Invalid Slate document structure',
      }),
    ]),
    price: z
      .number({
        required_error: 'Price is required',
        invalid_type_error: 'Price must be a number',
      })
      .min(1, { message: 'Price must be greater than or equal to $1' })
      .max(1001, { message: 'Price must be less than or equal to $1001' }),
    address: z
      .string()
      .min(5, 'Address should be between 5 and 250 characters.')
      .max(250, 'Address should be between 5 and 250 characters.')
      .refine((value) => !/[!$%&*+/<=>?@\\^_`{|}~]/.test(value), {
        message:
          'Please avoid using special characters like !$%&* etc. in the address.',
      }),
    postal: z
      .string()
      .min(6, { message: 'Postal code must be at least 6 digits long.' })
      .regex(/^\d+$/, {
        message: 'Postal code must only contain digits.',
      }),
    country: z.string(),
    timeZone: z.string(),
    studioType: z.string(),
    alwaysOpen: z.boolean().default(false),
    operatingHours: z.union([operatingHoursSchema, z.object({})]),
    imagesUpload: z
      .array(z.union([z.instanceof(File), z.string()]))
      .min(5, 'Please upload at least 5 images.'),
    termsAgreed: z.boolean().refine((val) => val, {
      message: 'You must accept the terms and conditions to continue.',
    }),
    minimumBookingDuration: z.number(),
  })
  .superRefine((data, ctx) => {
    if (data.alwaysOpen && Object.keys(data.operatingHours).length > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['operatingHours'],
        message:
          'Operating hours should be empty when always open is selected.',
      });
    } else if (
      !data.alwaysOpen &&
      Object.keys(data.operatingHours).length === 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['operatingHours'],
        message: 'Operating hours are required if not always open.',
      });
    }
  });
