import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Text,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker } from 'antd';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import useUserData from '@/hooks/useUserData';
import { useAuthStore, useUserProfileStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomInputField from '@/components/Forms/UsersField/CustomInputField';
import CustomLabel from '@/components/Forms/CustomLabel';
import AlertDialogComponent from '../../Utilities/AlertDialogComponent';
import {
  genderOptions,
  verifyContactMessage,
} from '@/components/Utilities/constants';
import { BorderedBox } from '@/components/Utilities/CustomBorder';
import ContactNumber from '@/components/DashBoard/UserProfile/ContactNumber';
import ContactNumberModal from '@/components/DashBoard/UserProfile/ContactNumberModal';
import { userProfileSchema } from '@/components/DashBoard/UserProfile/helper';
import OTPVerificationModal from '@/components/DashBoard/UserProfile/OTPVerificationModal';

const UserProfileEdit = () => {
  const MIN_AGE = 13;
  const MAX_AGE = 90;

  /* eslint-disable */
  const datePickerStyle = css`
    .ant-picker {
      border-radius: var(--chakra-radii-md);
      border: 1px solid var(--chakra-colors-gray-200);
      font-size: var(--chakra-fontSizes-md);
      height: var(--chakra-sizes-10);
      padding: var(--chakra-space-2);
      width: 49%;
      &:hover {
        border-color: var(--chakra-colors-gray-300);
      }
      &:focus {
        box-shadow: var(--chakra-shadows-outline);
        border-color: var(--chakra-colors-blue-500);
      }
    }`;
  /* eslint-enable */

  const { userData, setUserData, setCanEditContact } = useUserProfileStore(
    (state) => state,
  );
  const { user } = useAuthStore((state) => state);
  const userObject = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    dateOfBirth: user?.dateOfBirth || '',
    mobileNumber: user?.mobileNumber || '',
    gender: user?.gender || 'Male',
    email: user?.email || '',
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: userObject,
    resolver: zodResolver(userProfileSchema),
  });
  const { mutate, isPending } = useUserData();
  const showToast = useShowToastTop();
  useEffect(() => {
    if (user) {
      reset(userObject); // Reset form values to user data on load
    }
    setCanEditContact(!(user?.mobileNumber && user?.mobileNumber?.length > 0));
  }, [user, reset]);

  const handleChange = (date: any) => {
    setUserData({ ...userData, dateOfBirth: date });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = (data: any) => {
    const fullUserData = {
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
        mobileNumber: data.mobileNumber,
        gender: data.gender,
        email: data.email,
      },
    };
    mutate(fullUserData, {
      onSuccess: (response: any) => {
        showToast({
          message: response.message,
          status: 'success',
        });
        setCanEditContact(false);
        onClose();
      },
      onError: (error) => {
        const message = (error as any)?.response?.data?.errors[0];
        showToast({
          message,
          status: 'error',
        });
        onClose();
      },
    });
  };

  const disabledDate = (current: Dayjs | null) => {
    const today = dayjs();
    const minDate = today.subtract(MAX_AGE, 'year');
    const maxDate = today.subtract(MIN_AGE, 'year');
    if (!current) return false;
    return current.isBefore(minDate) || current.isAfter(maxDate);
  };

  const handleSave = () => {
    if (user?.otpVerifiedAt) {
      handleSubmit(() => onOpen())();
    } else {
      showToast({
        message: verifyContactMessage,
        status: 'error',
      });
    }
  };

  return (
    <Box maxW="500px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading fontSize={{ base: 'xl', md: '2xl' }}>Update Profile</Heading>
        <BorderedBox
          display="flex"
          flexDirection="column"
          gap={6}
          w="full"
          maxW={{ base: 'full', lg: '2xl' }}
          bg={useColorModeValue('white', 'gray.700')}
          rounded="xl"
          p={6}
          mt={15}
        >
          <HStack alignItems="center">
            <CustomInputField
              name="firstName"
              label="First name"
              type="text"
              control={control}
              defaultValue={user?.firstName}
              isRequired
            />
            <CustomInputField
              name="lastName"
              label="Last name"
              type="text"
              control={control}
              defaultValue={user?.lastName}
              isRequired
            />
          </HStack>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl
                mt={2}
                id="dateOfBirth"
                css={datePickerStyle}
                width="full"
                isInvalid={!!fieldState.error}
                isRequired
              >
                <CustomLabel labelText="Date of Birth" />
                <DatePicker
                  format="DD MMM YYYY"
                  onChange={(date) => {
                    const formattedDate = date
                      ? date.format('YYYY-MM-DD')
                      : null;
                    field.onChange(formattedDate);
                    handleChange(date);
                  }}
                  value={field.value ? dayjs(field.value) : null}
                  disabled={!!user?.dateOfBirth}
                  disabledDate={disabledDate} // Disable dates based on age restrictions
                  status={fieldState.error ? 'error' : ''}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <ContactNumber
            control={control}
            value={watch('mobileNumber')}
            errors={errors}
          />
          <Controller
            name="gender"
            control={control}
            defaultValue={user?.gender}
            render={({ field, fieldState }) => (
              <FormControl id="gender" isRequired>
                <CustomLabel labelText="Gender" />
                <RadioGroup colorScheme="honey" {...field} value={field.value}>
                  <Stack {...field} direction="row">
                    {genderOptions.map((option) => (
                      <Radio key={option.value} value={option.value}>
                        {option.label}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <FormControl mt={2} id="email">
            <CustomLabel labelText="Email Address" />
            {/* Instead of an input field, show text */}
            <Text fontSize="md" color="gray.700">
              {user?.email || 'No email provided'}
            </Text>
          </FormControl>
          <Stack spacing={[4, 6]} direction={['column-reverse', 'row']}>
            <Button
              colorScheme="honey"
              w="full"
              onClick={handleSave}
              isLoading={isPending}
            >
              Save
            </Button>
          </Stack>
        </BorderedBox>
        <AlertDialogComponent
          isOpen={isOpen}
          isPending={isPending}
          onClose={onClose}
          onConfirm={handleSubmit(onSubmit)}
          title="Confirm Profile Update"
          body="Are you sure you want to save the changes to your profile?"
        />
      </form>
      <ContactNumberModal />
      <OTPVerificationModal />
    </Box>
  );
};

export default UserProfileEdit;
