import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import APIClient from '@/services/api-client';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import {
  useAuthStore,
  UserDetails,
  useStripeConnectionStore,
} from '@/services/store';
import { commonErrorMessage } from '@/components/Utilities/constants';

export type StripeDetailsProps = {
  stripeAccountId: string;
  stripeCountry?: string;
  payoutsEnabled?: boolean;
  accountType?: string;
  chargesEnabled?: boolean;
  stripeAccountStatus: string;
  stripeBankName?: string;
  stripeRoutingNumber?: string;
  stripeLastFourDigits?: string;
};

export const useHandleStripeSignUp = (userId?: number) => {
  const { setIsSignUpBtnLoading } = useStripeConnectionStore();
  const navigate = useNavigate();
  const showToast = useShowToastTop();
  const apiClient = new APIClient<any>('/v1/payment_settings/sign_up');

  return useCallback(async () => {
    setIsSignUpBtnLoading();
    const redirectTo = encodeURIComponent(window.location.href);

    try {
      const response = await apiClient.post({ redirect_to: redirectTo });
      if (response?.data?.url) {
        window.open(response.data.url, '_blank');
      } else {
        showToast({
          message: response?.message || 'Failed to get URL.',
          status: 'error',
        });
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.status === 400
          ? error?.response?.data?.error
          : commonErrorMessage;
      showToast({
        message: errorMessage,
        status: 'error',
      });
    } finally {
      setIsSignUpBtnLoading();
    }
  }, [navigate, userId]);
};

export const handleStripeSignIn = () => {
  const stripeClientId = import.meta.env.VITE_STRIPE_CLIENT_ID;
  window.open(
    `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write`,
    '_blank',
  );
};

/* eslint-disable react-hooks/rules-of-hooks */
export const getStripeAccountDetails = (userId?: number) => {
  const { setIsPageLoading, updateStripeDetails } = useStripeConnectionStore();
  const { user, setAuth } = useAuthStore();
  const apiClient = new APIClient<any>(
    '/v1/payment_settings/stripe_account_details',
  );
  return useCallback(async () => {
    try {
      const response = await apiClient.get();
      if (response?.data?.stripeAccountId) {
        updateStripeDetails(response?.data);
        setAuth({
          ...user,
          stripeAccountId: response?.data?.stripeAccountId,
          stripeAccountStatus: response?.data?.stripeAccountStatus,
        } as UserDetails);
      } else {
        updateStripeDetails(null);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: any) {
      updateStripeDetails(null);
    } finally {
      setIsPageLoading(false);
    }
  }, [userId]);
};

export const disconnectStripeAccount = (userId?: number) => {
  const {
    setIsConfirmBtnLoading,
    stripeDetails,
    updateModalVisibility,
    updateStripeDetails,
  } = useStripeConnectionStore();
  const apiClient = new APIClient<any>('/v1/payment_settings');
  const showToast = useShowToastTop();
  const { user, setAuth } = useAuthStore();
  return useCallback(async () => {
    setIsConfirmBtnLoading();
    try {
      const response = await apiClient.delete();
      if (response?.data?.disconnected) {
        showToast({
          message: response.message,
          status: 'success',
          colorScheme: 'honey',
        });
        updateStripeDetails(null);
        setAuth({
          ...user,
          stripeAccountId: undefined,
          stripeAccountStatus: null,
        } as UserDetails);
      } else {
        throw new Error(commonErrorMessage);
      }
    } catch (error: any) {
      showToast({
        message: error?.response?.data?.error || commonErrorMessage,
        status: 'error',
      });
    } finally {
      setIsConfirmBtnLoading();
      updateModalVisibility(false);
    }
  }, [userId, stripeDetails]);
};

export const getStripeDashboardUrl = (): string =>
  import.meta?.env?.VITE_ENVIRONMENT === 'production'
    ? 'https://dashboard.stripe.com/dashboard'
    : 'https://dashboard.stripe.com/test/dashboard';

export const useHandleStripeCallback = (code?: string | null) => {
  const navigate = useNavigate();
  const showToast = useShowToastTop();
  const apiClient = new APIClient<any>('/v1/payment_settings/callback');

  return useCallback(async () => {
    try {
      const response = await apiClient.post({ code });
      if (response?.data?.redirectUrl) {
        navigate(response?.data?.redirectUrl);
        showToast({
          message: response?.message,
          status: 'success',
        });
      }
    } catch (error: any) {
      showToast({
        message: error?.response?.data?.error || commonErrorMessage,
        status: 'error',
      });
    }
  }, [navigate, showToast, code]);
};

export const stripeConnectionInfo = {
  description:
    'Musehive connects to your Stripe account to handle credit card payments and send payouts directly to your bank.',
  instruction:
    'If you already have a Stripe account, simply link it. If not, you can create a new one and start processing payments in just a few minutes.',
  statusInstructions:
    // eslint-disable-next-line
    "For more information on how to resolve or change your Stripe's account status, see the instructions that Stripe provides in your Stripe dashboard.",
};
/* eslint-enable react-hooks/rules-of-hooks */
